<template>
  <div class="main-frame">
    <div class="category-title">
      <div>마이페이지 <img class="title-icon" src="../assets/images/icon/icon_gray_right.png"> {{ categoryTitle }}</div>
      <h2>{{ categoryTitle }}</h2>
    </div>
    <div class="center-frame">
      <div class="side-bar">
        <div class="dropdown-box">
          <button class="tools-title" @click="sideBar.dropdown = !sideBar.dropdown">마이페이지
            <img v-if="sideBar.dropdown" src="@/assets/images/icon/icon_black_up.png" alt="arrow">
            <img v-else src="@/assets/images/icon/icon_black_down.png" alt="arrow">
          </button>
          <slide-up-down :active="sideBar.dropdown" :duration="500">
            <div class="side-bar-item" @click="changeTab(1)" :class="{active: sideBar.tabSubNum === 1}">내 구매 내역</div>
            <div class="side-bar-item" @click="changeTab(2)" :class="{active: sideBar.tabSubNum === 2}">내 찜 목록</div>
<!--            <div class="side-bar-item" @click="changeTab(2)" :class="{active: sideBar.tabSubNum === 2}">내 게시물 관리</div>-->
          </slide-up-down>
        </div>
      </div>
      <div class="container">
<!--        <button v-if="sideBar.tabSubNum === 2" class="add-item custom-btn" @click="addItem">게시물 등록</button>-->
        <div class="datatable">
          <mdb-datatable-2
              v-model="data"
              class="text-center"
              hover
              borderless
              defaultRow="-"
              fullPagination
              @selected='onSelected($event)'
              noFoundMessage="구매한 내역이 없습니다."/>
          <div class="data-null" v-show="isDataNull">
            <p>{{noFoundMessage}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import {mdbDatatable2,} from "mdbvue";
import SlideUpDown from "vue-slide-up-down";
import {firestore} from "@/firebase/firebaseConfig";
import user_paymentList_columns from "@/assets/data/columns/user_paymentList_columns";
import user_myItemList_columns from "@/assets/data/columns/user_myItemList_columns";
import {getDate} from "@/lib/prettyDate";
import {setNullDataDesign} from "@/lib/setNullDataDesign";

export default {
  name: 'MyPage',
  components: {
    SlideUpDown, mdbDatatable2
  },
  data() {
    return {
      fbCollection: 'receipt',
      sideBar: {
        dropdown: true,
        tabSubNum: 0,
      },
      isDataNull: true,
      categoryTitle: '',
      subCategoryTitle: '',
      data: {
        columns: user_paymentList_columns,
        rows: []
      },
      pluginList: [
        '내 구매 내역',
        '내 찜 목록',
        // '내 게시물 관리',
      ],
      noFoundMessage: '구매한 내역이 없습니다.',
    }
  },
  watch: {},
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const self = this;
      self.sideBar.tabSubNum = 0

      self.categoryTitle = '내 구매 내역';
      self.sideBar.tabSubNum = 1
      await self.getItem()
    },
    async getItem() {
      const self = this;
      self.isDataNull = true
      const uid = self.$store.state.uid
      const sideBarNum = self.sideBar.tabSubNum

      self.data = {
        columns: null,
        rows: []
      }
      if (sideBarNum === 1) {
        self.fbCollection = 'receipt'
        self.data.columns = [...user_paymentList_columns]
      } else if (sideBarNum === 2) {
        self.fbCollection = 'wishList'
        self.data.columns = [...user_myItemList_columns]
      }
      // else if (sideBarNum === 2) {
      //   self.fbCollection = 'item'
      //   self.data.columns = [...user_myItemList_columns]
      // }

      let docBasic = firestore.collection(self.fbCollection)
      if (sideBarNum === 1) {
        docBasic = docBasic
            .where('userInfo.uid', '==', uid)
            .orderBy('regDate', 'desc')
      } else {
        docBasic = docBasic
            .where('sellerInfo.uid', '==', uid)
            // .orderBy('lastDate', 'desc')
      }
      docBasic.get()
          .then((querySnapshot) => {
            if (querySnapshot.size === 0) {
              setNullDataDesign(self.isDataNull, self)
              return
            }
            self.isDataNull = false
            let _number = 0
            querySnapshot.forEach((doc) => {
              const _data = {
                orderNumber: ++_number,
                itid: doc.id,
              }
              if (sideBarNum === 1) {
                _data['docId'] = doc.data().itemInfo.docId
                _data['title'] = doc.data().itemInfo.title
                if (doc.data().isPayment)
                  _data['isPayment'] = '결제 완료'
                else
                  _data['isPayment'] = '결제 대기중'
                self.data.rows.push(_data);
              } else if (sideBarNum === 2) {
                _data['title'] = doc.data().title
                _data['price'] = doc.data().price.toLocaleString() + ' 원'

                const date = new Date(doc.data().lastDate.seconds * 1000);
                _data['lastDate'] = getDate(date)
                self.data.rows.push(_data);
              }
            });
          })
    },
    onSelected(value) {
      const self = this;
      const sideBarNum = self.sideBar.tabSubNum
      if (sideBarNum === 1)
        self.$router.push({name: 'MyPaymentDetail', params: {uid: value.docId, itid: value.itid}})
      else if (sideBarNum === 2)
        self.$router.push({name: 'MyItemDetail', params: {uid: value.itid}})

    },
    async changeTab(value) {
      const self = this;
      self.sideBar.tabSubNum = value
      self.categoryTitle = self.pluginList[value - 1];
      self.noFoundMessage = '찜한 게시물이 없습니다'
      // self.noFoundMessage = '게시물을 등록해 주세요'
      await self.getItem()
    },
    addItem() {
      const self = this;
      self.$router.push({name: 'AddItem'})
    },
  },
}
</script>
<style scoped>

.main-frame {
  width: 1224px;
  margin: 0 auto;
  padding: 60px 16px;
}

.main-frame .category-title {
  text-align: start;
  margin-bottom: 20px;
}

.main-frame .category-title div {
  color: #71717A;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.main-frame .category-title div .title-icon {
  width: 18px;
  height: 18px;
}

.main-frame .category-title h2 {
  color: #71717A;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}

.main-frame .center-frame {
  display: flex;
}

.main-frame .center-frame .side-bar {
  text-align: start;
  width: 234px;
  height: 100%;
  margin-right: 16px;
}

.center-frame .side-bar .dropdown-box {
  margin-bottom: 10px;
}

.center-frame .side-bar .tools-title {
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #E4E4E7;
  background: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.28px;
}

.center-frame .side-bar .tools-title img {
  width: 24px;
  height: 24px;
}

.center-frame .side-bar .side-bar-item {
  height: 42px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  display: flex;
  align-items: center;
  color: #71717A;
  cursor: pointer;
}

.center-frame .side-bar .side-bar-item.active {
  color: #FF8A00;
}

.center-frame .container {
  min-height: 500px;
  width: 942px;
  padding: 10px;
  text-align: end;
}

.center-frame .container .add-item {
  margin-bottom: 10px;
}

::v-deep(.leader) {
  border-radius: 100px;
  border: 3px solid #4C9AF5;
  padding: 0;
  color: #4C9AF5;
  font-weight: 500;
}

::v-deep(.mdb-datatable .table-header tr) {
  background: #f4f4f5;
  border-top: 2px #E4E4E7 solid;
  padding: 0 16px;
}

::v-deep(.mdb-datatable td) {
  cursor: pointer !important;
  padding: 14px 0 !important;
  font-size: 16px;
}

::v-deep(.mdb-datatable tr) {
  border-top: 1px #E4E4E7 solid;
  padding: 0 16px;
}

::v-deep(.mdb-datatable tr:hover) {
  background: #fafafa;
}

::v-deep(.mdb-datatable .table-header th),
::v-deep(.mdb-datatable td) {
  font-weight: 500;
  color: #71717A;
  padding: 14px 0 14px;
  font-size: 16px;
}

::v-deep(.mdb-datatable tr:nth-last-child(1)) {
  border-bottom: 2px #E4E4E7 solid;
}

::v-deep(.mdb-datatable th:first-child),
::v-deep(.mdb-datatable td:first-child) {
  width: 70px;
  padding-left: 16px;
  padding-right: 10px;
}


</style>