export default [
    {
        label: '번호',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '제목',
        field: 'title',
        sort: true
    },
    {
        label: '가격',
        field: 'price',
        sort: true
    },
    {
        label: '업데이트',
        field: 'lastDate',
        sort: true
    },
];
